<template>
    <footer class="bg-gray-900" aria-labelledby="footer-heading">
        <h2 id="footer-heading" class="sr-only">Footer</h2>
        <div class="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-24">
            <div class="xl:grid xl:grid-cols-3 xl:gap-8">
                <div class="space-y-8">
                    <UtilsLogoAMSN class="!h-10" alt="Company name" />
                    <div>
                        <p class="text-sm leading-6 text-gray-300">{{ $t('company.name&status') }}</p>
                        <p class="text-sm leading-6 text-gray-300">{{ $t('company.address') }}</p>
                        <p class="text-sm leading-6 text-gray-300">{{ $t('company.phone') }}</p>
                    </div>
                    <div class="flex space-x-6">
                        <NuxtLink v-for="item in navigation.social" :key="item.name" :to="item.href" target="_blank" class="text-gray-500 hover:text-gray-400">
                            <span class="sr-only">{{ item.name }}</span>
                            <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                        </NuxtLink>
                    </div>
                </div>
                <div class="mt-16 grid grid-cols-3 gap-8 xl:col-span-2 xl:mt-0">
                    <div>
                        <h3 class="text-sm font-semibold leading-6 text-white">{{ $t('footer.services.title') }}</h3>
                        <ul role="list" class="mt-6 space-y-4">
                            <li v-for="item in navigation.solutions" :key="item.name">
                                <NuxtLink :to="localePath(item.href)" class="text-sm leading-6 text-gray-300 hover:text-white">{{ item.name }}</NuxtLink>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 class="text-sm font-semibold leading-6 text-white">{{ $t('footer.company.title') }}</h3>
                        <ul role="list" class="mt-6 space-y-4">
                            <li v-for="item in navigation.company" :key="item.name">
                                <NuxtLink :to="localePath(item.href)" class="text-sm leading-6 text-gray-300 hover:text-white">{{ item.name }}</NuxtLink>
                            </li>
                        </ul>
                    </div>
                    <div class="mt-10 md:mt-0">
                        <h3 class="text-sm font-semibold leading-6 text-white">{{ $t('footer.legal.title') }}</h3>
                        <ul role="list" class="mt-6 space-y-4">
                            <li v-for="item in navigation.legal" :key="item.name">
                                <NuxtLink :to="localePath(item.href)" class="text-sm leading-6 text-gray-300 hover:text-white">{{ item.name }}</NuxtLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="mt-16 border-t border-white/10">
                <p class="text-xs leading-5 text-gray-400">&copy; {{ currentYear }} {{ $t('company.name') }}, Inc. {{ $t('footer.all-rights-reserved') }}</p>
            </div>
        </div>
    </footer>
</template>

<script setup>
import { defineComponent, h } from 'vue'
import {useI18n} from "vue-i18n";

const { t } = useI18n();

const currentYear = new Date().getFullYear();

const navigation = {
    solutions: [
        { name: t('services.cyber-security'), href: '/cybersecurity' },
        { name: t('services.security'), href: '/security' },
        { name: t('services.smart-home'), href: '/smart-home' },
        { name: t('services.cloud'), href: '/cloud' },
        { name: t('services.blockchain'), href: '/blockchain' },
    ],
    company: [
        { name: t('footer.company.certifications'), href: '/certifications' },
        { name: t('footer.company.careers'), href: '/careers' },
        { name: t('footer.company.support'), href: '/support' },
        { name: t('footer.company.about-us'), href: '/about-us' },
        { name: t('footer.company.fond-bleu'), href: '/fond-bleu' },
    ],
    legal: [
        { name: t('footer.legal.legal-info'), href: '/legal' },
        { name: t('footer.legal.cookies-policy'), href: '/cookies-policy' },
    ],
    social: [
        {
            name: 'Instagram',
            href: 'https://www.instagram.com/mve.mc/',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                        h('path', {
                            'fill-rule': 'evenodd',
                            d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
                            'clip-rule': 'evenodd',
                        }),
                    ]),
            }),
        },
        {
            name: 'Twitter',
            href: 'https://twitter.com/MveMonaco',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                        h('path', {
                            d: 'M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84',
                        }),
                    ]),
            }),
        },
        {
            name: 'LinkedIn',
            href: 'https://www.linkedin.com/company/25079285',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                        h('path', {
                            'fill-rule': 'evenodd',
                            d: 'M17.6429 1.4375H1.3529C0.606473 1.4375 0 2.08887 0 2.88848V20.1115C0 20.9111 0.606473 21.5625 1.3529 21.5625H17.6429C18.3893 21.5625 19 20.9111 19 20.1115V2.88848C19 2.08887 18.3893 1.4375 17.6429 1.4375ZM5.74241 18.6875H2.92634V9.0832H5.74665V18.6875H5.74241ZM4.33437 7.77148C3.43103 7.77148 2.70156 6.99434 2.70156 6.04199C2.70156 5.08965 3.43103 4.3125 4.33437 4.3125C5.23348 4.3125 5.96719 5.08965 5.96719 6.04199C5.96719 6.99883 5.23772 7.77148 4.33437 7.77148V7.77148ZM16.2984 18.6875H13.4824V14.0156C13.4824 12.9016 13.4612 11.4686 12.0192 11.4686C10.5518 11.4686 10.327 12.6814 10.327 13.9348V18.6875H7.51094V9.0832H10.2125V10.3949H10.2507C10.6281 9.64023 11.5484 8.84512 12.9183 8.84512C15.7683 8.84512 16.2984 10.8352 16.2984 13.4227V18.6875V18.6875Z',
                            'clip-rule': 'evenodd',
                        }),
                    ]),
            }),
        },
        {
            name: 'What\'s App',
            href: 'https://wa.me/33674813440',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                        h('path', {
                            'fill-rule': 'evenodd',
                            d: 'M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z',
                            'clip-rule': 'evenodd',
                        }),
                    ]),
            }),
        },
    ],
}
</script>
